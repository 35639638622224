<template>
    <div>
        <div class="d-flex align-items-center flex-nowrap" :id="config.id">
            <input
                type="text"
                v-model="dateYear"
                :maxlength="4"
                style="width: 80px; padding-right: 0 !important"
                class="form-control mr-2"
                :class="{
                    'is-invalid': config?.error,
                    disabled_cursor: disabled
                }"
                :disabled="disabled"
                @keypress="inputYear($event)"
            />
            {{ this.$t('text.year') }}
            <input
                type="text"
                v-model="dateMonth"
                :maxlength="2"
                style="width: 47px; padding-right: 0 !important"
                class="form-control mx-2"
                :class="{
                    'is-invalid': config?.error,
                    disabled_cursor: disabled
                }"
                :disabled="disabled"
                @keypress="inputMonth($event)"
                @input="limitMonthValue"
            />
            {{ this.$t('text.month') }}
            <input
                type="text"
                v-model="dateDay"
                :maxlength="2"
                style="width: 47px; padding-right: 0 !important"
                class="form-control mx-2"
                :class="{
                    'is-invalid': config?.error,
                    disabled_cursor: disabled
                }"
                :disabled="disabled"
                @keypress="inputDay($event)"
            />
            {{ this.$t('text.day') }}
            <button
                class="btn-sm btn btn-info mx-2 box-date"
                :class="{
                    disabled_cursor: disabled
                }"
                type="button"
                style="min-width: fit-content"
                :disabled="disabled"
            >
                {{ this.$t('btn.selectCalendar') }}
                <DatePicker
                    ref="scheduled_end_date"
                    v-model="fullDate"
                    :value-type="customFormatDate"
                    :first-day-of-week="1"
                    lang="ja"
                    :disabled="disabled"
                ></DatePicker>
            </button>
        </div>
        <slot />
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { isNumber } from '@/utils/format';

export default {
    name: 'DatePickerCommon',
    components: {
        DatePicker
    },
    props: {
        id: {
            type: String,
            required: false,
            default: 'select-date'
        },
        year: {
            type: String,
            required: false,
            default: ''
        },
        month: {
            type: String,
            required: false,
            default: ''
        },
        day: {
            type: String,
            required: false,
            default: ''
        },
        full_date: {
            type: null,
            required: true,
            default: null
        },
        config: {
            type: Object,
            required: true,
            default() {
                return {
                    error: false
                };
            }
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            date: this.value,
            customFormatDate: 'YYYY-MM-DD',
            dateYear: '',
            dateMonth: '',
            dateDay: '',
            fullDate: ''
        };
    },
    watch: {
        '$props.year': {
            handler: function (newYear) {
                this.dateYear = newYear;
            },
            immediate: true
        },
        '$props.month': {
            handler: function (newMonth) {
                this.dateMonth = newMonth;
            },
            immediate: true
        },
        '$props.day': {
            handler: function (newDay) {
                this.dateDay = newDay;
            },
            immediate: true
        },
        '$props.full_date': {
            handler: function (newFullDate) {
                this.fullDate = newFullDate ?? '';
                this.updateDate(this.fullDate);
            },
            immediate: true
        },
        '$props.config': {
            handler: function (newConfig) {
                this.config = newConfig;
                this.$emit('update:config', this.config);
            },
            immediate: true
        },
        fullDate: {
            handler: function (newFullDate) {
                this.fullDate = newFullDate;
                this.updateDate(this.fullDate);
            }
        },
        dateYear: {
            handler: function (newYear) {
                this.$emit('update:year', newYear);
                this.handleInput();
            }
        },
        dateMonth: {
            handler: function (newMonth) {
                this.$emit('update:month', newMonth);
                this.handleInput();
            }
        },
        dateDay: {
            handler: function (newDay) {
                this.$emit('update:day', newDay);
                this.handleInput();
            }
        }
    },
    methods: {
        updateDate(newFullDate) {
            if (!newFullDate) {
                this.dateYear = '';
                this.dateMonth = '';
                this.dateDay = '';
            } else {
                const date = newFullDate.split('-');
                this.dateYear = date[0];
                this.dateMonth = date[1];
                this.dateDay = date[2];
            }
            let config = this.config;
            config.error = false;
            this.$emit('update:config', config);
            this.$emit('update:year', this.dateYear);
            this.$emit('update:month', this.dateMonth);
            this.$emit('update:day', this.dateDay);
            this.$emit('update:full_date', this.fullDate);
        },
        handleInput() {
            if (!this.dateYear && !this.dateMonth && !this.dateDay) {
                this.fullDate = '';
                return;
            }
            this.fullDate = `${this.dateYear}-${this.dateMonth}-${this.dateDay}`;
        },
        inputYear(event) {
            isNumber(event);
        },
        inputMonth(event) {
            isNumber(event);
        },
        limitMonthValue() {
            if (this.dateMonth > 12) {
                this.dateMonth = '';
            }
        },
        inputDay(event) {
            isNumber(event);
        }
    }
};
</script>

<style lang="scss" scoped></style>
